import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import GlobalContext from '../../../../context/GlobalContext';
import { updateQueryParam } from '../../../../utils/helperFn';
import { Button, Text, Section } from '../../../Core';
import { navigate } from 'gatsby';

interface Props { }

const BusinessFormNotification = (props: Props) => {
  const gContext: any = useContext(GlobalContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    updateQueryParam('step', '0')
  }, []);

  return (
    <Section>
      <Row className="justify-content-center">
        <Col lg={6} md={6} sm={12} className="text-center mb-3 mt-3">
          <Text className="mb-4">Our records show us that <strong>{gContext?.goGetRegistrationData()?.searchPremiseResults?.title}</strong> is a business premise, is that correct?</Text>
          <Button
            className="medium-button blue-bg mr-3"
              onClick={() => {
                gContext.goResetRegistrationData()
                navigate('/')
                gContext.setAPIErrorMsg({
                  title: 'Info',
                  message: 'Please call our sales department on <a href="tel:03334439694">0333 443 9694</a> who will be happy to assist you with placing your order.'
                })
              }}
            >
            No
          </Button>
          <Button
            className="medium-button green-bg"
              onClick={() => {
                gContext.goSetRegistrationData({
                  ...gContext.goGetRegistrationData(),
                  registerStep: 1,
                });
              }}
            >
              Yes
            </Button>
        </Col>
      </Row>
    </Section>
  );
};

export default BusinessFormNotification;
